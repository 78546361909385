import styled from 'styled-components';

import AppSecreenBG from 'assets/images/app/get-app/get-app-bg.png';

const AppScreenSctionWrapper = styled.section`
  padding: 130px 0 110px 0;
  background: url(${AppSecreenBG}) no-repeat;
  background-size: cover;
  background-position: center;

  .view-more-button {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .banner-btn.one{
    background: #0152cc;
    color: #fff;
    &:hover{
        background: rgb(35, 114, 217);
        color: #fff;
    }
}

  @media only screen and (max-width: 568px) {
    .section-title-block{
      text-align: center;
      h4{
        width: 100%;
      }
      img{
        display: none;
      }
    }
    .view-more-button{
      display: none;
    }
  }
`;

export default AppScreenSctionWrapper;
