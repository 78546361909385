import React from "react";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";
import Particle from "reusecore/Particle";

import { FaPlay, FaRegUserCircle } from "react-icons/fa";

import BannerSectionWrapper from "./banner.style";

const BannerDefault = () => {
  return (
    <BannerSectionWrapper id='home'>
      <Particle />
      <Container fullWidthSM>
        <Row>
          <Col sm={7} md={6} lg={7}>
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h4>Free access for a limited time!</h4>
              <h1>
                Make change great again with  <span>Change Pro</span>
              </h1>
            </SectionTitle>
            <p>
              Collaborative organisational change management platform for organisations of all sizes.
            </p>
            <VintageBox right={true} vintageOne={true}>
              <Button className="banner-btn one" onClick={() => window.open('https://change.sortd.io', '_blank')}>
                <FaRegUserCircle className="icon-left" />
                Sign-Up
              </Button>
              <Button className="banner-btn two">
                <FaPlay className="icon-left" /> Learn More
              </Button>
            </VintageBox>
          </Col>
        </Row>
      </Container>
    </BannerSectionWrapper>
  );
};

export default BannerDefault;
