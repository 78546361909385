import React from "react";
import {ThemeProvider} from 'styled-components';

import Layout from "components/layout" 
import SEO from "components/seo" 

import Navigation from 'sortd_sections/change/Navigation'; 
import Banner from 'sortd_sections/change/Banner'; 

import Contact from 'sortd_sections/shared/Contact';
import Footer from 'sortd_sections/shared/Footer';

import { GlobalStyle } from 'sortd_sections/shared/app.style';
import theme from 'theme/app/themeStyles';
import Pricing from "sortd_sections/change/Pricing";
import CTA from "sortd_sections/change/CTA";
import Features from "sortd_sections/change/Features";
import AppScreens from "sortd_sections/change/AppScreens";

const IndexPage = () => (
  <> 
      <SEO title="Change Pro" />
      <Banner />
      <Features />
      <AppScreens />
      <CTA />
      <Pricing />
      <Contact />
  </>
)

export default IndexPage
